<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="610px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between">
        <span
          class="font-weight-bold primary--text"
          :class="{
            'text-h4': $vuetify.breakpoint.smAndUp,
            'text-h5': $vuetify.breakpoint.xsOnly
          }"
        >
          Gestión de Permiso de usuario
        </span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingPerm || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <validation-observer ref="ADMIN_PERMISO_FORM" tag="div">
          <v-row>
            <v-col cols="12" class="pt-2 pb-0">
              <label-form text="Nombre" required />
              <validation-provider
                name="Nombre"
                vid="permisoInfo.name"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :value="data.name"
                  v-model="permisoInfo.name"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="actionPermiso"
        >
          <v-icon dark left>mdi-pencil</v-icon>
          {{ action === 'editar' ? 'Actualizar' : 'Crear Permiso' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const dataDefault = () => ({
  nombre: ''
})
export default {
  name: 'ModalPermiso',
  props: {
    value: Boolean,
    data: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'editar'
    }
  },
  data() {
    return {
      showModal: this.value,
      loadingPerm: false,
      permisoInfo: dataDefault()
    }
  },
  watch: {
    showModal(val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value(val, oldVal) {
      if (val === oldVal) return

      this.showModal = val
    },
    data(val) {
      if (Object.values(val).length > 0) {
        this.permisoInfo = { ...val }
      } else this.permisoInfo = dataDefault()
    }
  },
  methods: {
    cerrar() {
      this.showModal = false
      this.permisoInfo = dataDefault()
      this.$refs.ADMIN_PERMISO_FORM.reset()
    },
    async actionPermiso() {
      const valid = await this.$refs.ADMIN_PERMISO_FORM.validate()
      if (valid) {
        try {
          this.loadingPerm = true
          const { data } = await this.$api({
            method: this.action === 'crear' ? 'post' : 'put',
            url:
              this.action === 'crear'
                ? 'users/permisos'
                : 'users/permisos/' + this.permisoInfo.id,
            data: { name: this.permisoInfo.name }
          })
          this.cerrar()
          this.$emit('procesado')
          this.$root.$showAlert(data.message, 'success')
        } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
            'error'
          )
        } finally {
          this.loadingPerm = false
        }
      }
    }
  }
}
</script>
